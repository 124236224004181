<template>
  <div class="bg" style="position: relative; height: 93vh; background: #f8f8f8">
    <div class="bg-2"></div>
    <div style="padding-top: 20px">
      <el-row style="">
        <el-col :span="12" :offset="6">
          <div class="box">
            <h1 style="text-align: left; font-weight: bold; margin-bottom: 20px">编辑个人信息</h1>
            <el-form class="form" ref="form" :model="form" label-width="80px" label-position="left">
              <el-form-item label="用户昵称" style="width: 25vw">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label="上传头像" style="margin-bottom: 24vh; width: 15vw">
                <el-upload
                  class="avatar-uploader"
                  :action="actions"
                  :data="params"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :on-error="handleAvatarError"
                  :before-upload="beforeAvatarUpload"
                >
                  <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item>
                <el-button style="margin-right: 20px" @click="$router.go(-1)">返回</el-button>
                <el-button type="primary" @click="submit">提交</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { set_user, getUser } from "@/api/user.js";

export default {
  name: "setting",
  data() {
    return {
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      params: {},
      actions: "api/users/set_user",
      imageUrl: "",
    };
  },
  mounted() {
    this.actions = process.env.VUE_APP_API_BASE_URL + "api/users/set_user";
    this.params.token = localStorage.getItem("token");
    // 渲染视频
    const { name, face } = JSON.parse(localStorage.getItem("userInfo"));
    this.form.name = name || "";
    this.imageUrl = face || "";
  },
  methods: {
    handleAvatarSuccess(res, file) {
      console.log(file);
      this.$message.success("上传头像成功!");
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    handleAvatarError(res, file) {
      console.log(res);
    },
    submit() {
      set_user({ name: this.form.name }).then((res) => {
        console.log(res);
        if (res.code == "200") {
          this.$message.success("修改成功!");
          getUser({ token: res.data.token }).then((res1) => {
            if (res1.code == "200") {
              localStorage.setItem("userInfo", JSON.stringify(res1.data));
              setTimeout(() => {
                this.$router.go(-1);
              }, 500);
            }
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    beforeAvatarUpload(file) {
      const isType = file.type === "image/jpeg" || file.type === "image/png";
      const isLt5M = file.size / 1024 / 1024 < 5;

      if (!isType) {
        this.$message.error("上传头像图片只能是 JPG 或 PNG 格式!");
      }
      if (!isLt5M) {
        this.$message.error("上传头像图片大小不能超过 5MB!");
      }
      return isType && isLt5M;
    },
    logout() {
      console.log(this.$store.state.mInterval);
      clearInterval(this.$store.state.mInterval);
      localStorage.clear();
      this.$router.push({ name: "login" });
    },
  },
};
</script>
<style lang="less" scoped>
.bg-2 {
  background: url("../../assets/img/bg1.png");
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 30vh;
}
.box {
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  background: #fff;
  margin: 60px 0;
  margin-bottom: 0;
  height: 70vh;
  padding: 80px 50px;
  border-radius: 9px;
  overflow: hidden;

  .form {
    width: 40vw;
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px solid #8c939d;
  border-radius: 8px;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
